import React from 'react'

const Footer = () => {
    return (
        <footer>
            <div className="footer-wrapper">
                <div className='footer-logo-section'>
                    <div className='footer-logo-wrapper'>
                        <img src="images/logo-transparent.svg" alt="logo" className='logo'/>
                    </div>

                    <p>
                        Разполагаме с машини от последно поколение за ефективно и безопасно почистване на DPF и FAP филтри. Сертифицирани сме по ISO 9001, което гарантира качеството на нашите услуги и удовлетвореността на клиентите.
                    </p>

                    <img src="images/icon-instagram.svg" alt="instagram" width={24} height={24}/>

                    <a href="https://www.facebook.com/profile.php?id=100063626815548" target='_blank'>
                        <img src="images/icon-facebook.svg" alt="facebook" width={24} height={24}/>
                    </a>
                </div>

                <div className='footer-company-contacts-wrapper'>
                    <div className='footer-company'>
                        <span>Компания</span>
                        <ul>
                            <li>Начало</li>
                            <li>Услуги</li>
                            <li>За нас</li>
                        </ul>
                    </div>           

                    <div className='footer-contacts'>
                        <span>Контакти</span>
                        <ul>
                            <li>
                                <img src="images/location.svg" alt="loacation" width={18} height={18}/>
                                гр. Поморие, Околовръстен път Бургас-Варна, до КПП
                            </li>
                            <li>
                                <img src="images/call-green.svg" alt="call" width={18} height={18}/>
                                +359 887 776 177 
                            </li>
                        </ul>
                    </div>
                </div>

                {/* <div>
                    <iframe 
                        title='iframe'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2938.0111357277206!2d27.60430661110116!3d42.57628612129419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a6972745ef08d3%3A0x92857d9d8d65a88a!2zRFBGIC0gQ2xlYW5pbmcg0J_QvtGH0LjRgdGC0LLQsNC90LUg0L3QsCDQlNCf0KQv0KTQkNCfINGE0LjQu9GC0YrRgCDQuCDQutCw0YLQsNC70LjQt9Cw0YLQvtGA0Lg!5e0!3m2!1sbg!2sbg!4v1728245160618!5m2!1sbg!2sbg" 
                        className='iframe'
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div> */}
            </div>

            <style jsx>{`
                footer {
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    background-color: var(--black-color); 
                    color: #ffffff;            
                    padding: 8rem;
                }
                .footer-logo-section {
                    max-width: 30%;
                }
                .footer-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .foooter-logo-wrapper {
                    display: flex;
                    align-items: center;
                }
                .logo {
                    width: fit-content;
                    height: 5rem;
                }
                .footer-company {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    align-self: flex-start;
                    padding-top: 2rem;
                }
                .footer-company ul {
                    padding: 0;
                    list-style: none;
                }
                .footer-company li {
                    margin-bottom: 1.5rem;
                }
                .footer-contacts {
                    align-self: flex-start;
                    padding-top: 2rem;
                }
                .footer-contacts ul {
                    list-style: none;
                    padding: 0; 
                }
                .footer-contacts li {
                    display: flex;
                    align-items: center;
                    gap: 1rem; 
                    margin-bottom: 1.5rem;
                }
                .icons-wrapper {
                    display: flex;  
                    gap: 1rem;
                    padding-left: var(--desktop-gap);
                }
                .icons {
                    width: 2rem;
                    height: 2rem;
                }
                p {
                    align-self: center;
                }
                iframe {
                    width: 500px; 
                    height: 325px; 
                    border: 0;
                }
                @media screen and (max-width: 480px) {
                    footer {
                        padding: 1rem;
                    }
                    .wrapper {
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .footer-wrapper {
                        flex-direction: column;
                    }
                    .footer-logo-section {
                        max-width: unset; 
                    }
                    .logo {
                        margin: 0 auto;
                    }
                    .icons {
                        margin: 0 auto;
                    }
                    ul, .icons-wrapper {
                        padding-inline-start: 0;
                        -webkit-padding-inline-start: 0;
                        margin-bottom: 1rem
                    }
                    ul {
                        max-width: calc(100vw - 2*var(--mobile-gap));
                        width: fit-content;
                    }
                    iframe {
                        width: calc(100vw - var(--desktop-gap)); 
                        border: 0;
                    }
                    .footer-company-contacts-wrapper {
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                    }
                    .footer-company-contacts-wrapper span {
                        margin-bottom: 2rem;
                    }
                }
            `}</style>
        </footer>
    )
}

export default Footer