import React from 'react';

const ContactBar = () => {

    return (
        <div className='ContactBar'>
            <div className="socials-wrapper">
                <img src="images/icon-instagram.svg" alt="insta"/>

                <a href="https://www.facebook.com/profile.php?id=100063626815548" target='_blank'>
                    <img src="images/icon-facebook.svg" alt="face"/>
                </a>
            </div>

            <div className='contact-urls-wrapper'>
                <a href='https://maps.app.goo.gl/UE6Qehp1pZTysC859' className='contact-wrapper'>
                    <img src="/images/location.svg" alt="icon-location-pin" />
                    <span>
                        гр.Поморие, Околовръстен път Бургас - Варна, до КПП
                    </span>
                </a>
                <div className='contact-wrapper'>
                    <img src="/images/clock-circle.svg" alt="icon-clock" />
                    <span>09:00 - 18:00</span>
                </div>
            </div>

            <style jsx>{`
                .ContactBar {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: space-between;
                    height: 2.5rem;
                    color: var(--white-color);
                    background: var(--black-color);
                    padding: 0.5rem var(--desktop-gap) 0.5rem var(--desktop-gap);
                }
                .contact-urls-wrapper {
                    display: flex;
                    gap: 1rem;
                    justify-content: space-between;
                }
                .contact-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                }
                .socials-wrapper {
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;
                }
                .ContactBar img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
                @media screen and (max-width: 480px) {
                    .ContactBar {   
                        padding: 0; 
                        height: 4rem;
                        width: 100vw;
                    }
                    .contact-urls-wrapper {
                        padding: 1rem;
                        max-width: unset;
                    }
                    div.contact-wrapper {
                        display: none;
                    }
                    a.contact-wrapper { 
                        font-size: 0.75rem;
                        text-align: center;
                        max-width: 85%;
                    }
                    .socials-wrapper {
                        display: none;
                    }
                }
            `}</style>
        </div>
    )
}

export default ContactBar