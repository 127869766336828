import React from 'react'

const UsefulInformation = () => {

    return (
        <div className='UsefulInformation'>
            <div className='useful-information-header'>
                <span>
                    От значение за вашия автомобил
                </span>
                <h3>
                    Полезна информация за DPF филтрите
                </h3>
            </div>
            <div className='useful-information-card-wrapper'>
                <div className='useful-information-card'>
                    <h4>
                        Неподдръжането на DPF филтрите може да доведе до
                    </h4>
                    <ul>
                        <li>Загуба на мощност на двигателя</li>
                        <li>Увеличен разход на гориво</li>
                        <li>Възможност за сериозни повреди на двигателя</li>
                        <li>Повишени емисии и глоба от органите за контрол на замърсяването</li>
                    </ul>
                </div>

                <div className='useful-information-card'>
                    <h4>
                        Признаци за проблеми с DPF филтър
                    </h4>
                    <ul>
                        <li>Светеща лампа на таблото за управление</li>
                        <li>Авариен режим на автомобила</li>
                        <li>Чести опити за регенерация</li>
                        <li>Нарастващ разход на гориво</li>
                        <li>Загуба на мощност</li>
                        <li>Повишени емисии</li>
                    </ul>
                </div>

                <div className='useful-information-card'>
                    <h4>
                        Какво предлагаме?
                    </h4>
                    <ul>
                        <li>Почистване на DPF/FAP филтър</li>
                        <li>Физическо и софтуерно премахване на DPF/FAP филтър</li>
                        <li>Проверка на системата и диагностика</li>
                        <li>Съвети за правилна поддръжка</li>
                    </ul>
                </div>
            </div>
            <style jsx>{`
                .UsefulInformation {  
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 3rem;
                    padding: 4rem;
	                color: var(--black-color);
                }
                .useful-information-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center; 
                    align-items: center;
                    width: 50%;
                }
                .useful-information-header span {
                    color: var(--primary-color);
                    font-size: 1.25rem;
                    font-weight: 900;
                    font-style: italic;
                }
                .useful-information-header h3 {
                    font-style: italic; 
                    font-weight: 900;
                    font-size: 3rem;
                }
                .useful-information-card-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 2rem; 
                }
                .useful-information-card {
                    padding: 1rem 1.5rem;
                    border: 0.125rem solid var(--primary-color);
                    width: 33%;
                    height: 22rem;
                    display: flex;
                    flex-direction: column;
                }
                .useful-information-card h4 {
                    font-style: italic; 
                    font-weight: 900;
                    font-size: 1.25rem;
                }   
                @media screen and (max-width: 480px) {
                    .UsefulInformation {
                        padding: 1rem;
                    }
                    .useful-information-header {
                        width: unset;
                    }
                    .useful-information-header h3 {
                        font-size: 1.5rem;
                    }
                    .useful-information-card-wrapper {
                        flex-direction: column;
                    }
                    .useful-information-card {
                        width: calc(100vw - *var(--desktop-gap));
                        height: unset;
                    }
                }
            `}</style>
        </div>
    )
}

export default UsefulInformation