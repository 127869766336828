import React from 'react'

const GreenLine = () => {
  return (
    <div className='GreenLine'>
        <div className='green-line-text'>
            Върни мощността на своя двигател – обади се сега за почистване!
        </div>
        <div className='green-line-button-wrapper'>
            <button className='green-line-button'>
                +359 887 776 17
            </button>
        </div>
        <style jsx>{`
            .GreenLine {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: var(--primary-color);
                padding: 1rem 1.5rem;
                color: var(--white-color);
            }
            .green-line-text {
                font-size: 1.25rem;
                font-style: italic;
                font-weight: 700;
                text-transform: uppercase;
            }
            .green-line-button-wrapper {
                
            }
            .green-line-button {
                color: var(--primary-color);
                background: var(--white-color);
                border-radius: 0;
                border: none;
            }
            @media screen and (max-width: 480px) {
                .GreenLine {
                    flex-direction: column;
                    gap: 1rem;
                    text-align: center;
                }
                .green-line-text {
                    font-size: 0.875rem;
                }
            }
        `}</style>
    </div>
  )
}

export default GreenLine