import React from 'react'
import {Link} from 'react-router-dom'
import {routes} from '../routes'

const Hero = () => {

    return (
        <div className='Hero'>
            <video className='video' autoPlay loop muted>
                <source src="images/intro-video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className='h1-h2-wrapper'>
                <h1 className='heading1'>
                    Почистване на DPF/FAP
                    филтри и катализатори
                </h1>
                <h2>
                    Възстановете мощността на вашия автомобил и удължете живота на 
                    двигателя с нашето специализирано DPF/FAP почистване на филтри и катализатори
                </h2>
                <h2 className='h2-mobile'>
                    Възстановете мощността на вашия автомобил и удължете живота на двигателя с нашето специализирано DPF/FAP почистване на филтри и катализатори
                </h2>

                <div className='other-wrapper'>
                    <Link to={routes.services} className='our-services-button'>
                        Нашите услуги
                    </Link>

                    <div className='consultation-wrapper'>
                        <img src="images/calling.svg" alt="calling" width={36} height={36}/>
                        <div className='consultation'>
                            <span className='phone-number'>+359 887 776 177</span>
                            <span>свържете се за консултация</span>
                        </div>
                    </div>
                </div>
            </div>

            <img src="/images/iso_9001_new.svg" alt="hero" className='iso' width={172} height={172}/>

            <style jsx>{`
                .Hero {
                    width: 100%;
                    height: 37.5rem;
                    position: relative; 
                    color: var(--white-color);
                }
                .video {
                    width: 100%;
                    height: 37.5rem;
                    object-fit: cover;
                    pointer-events: none;
                }
                .h1-h2-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 2.5rem;
                    transform: translateY(-50%);
                }
                .h1-h2-wrapper h1 {
                    max-width: 60%;
                    text-transform: uppercase;
                } 
                .h1-h2-wrapper h2 {
                    max-width: 50%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .h2-mobile {
                    display: none; 
                }
                .other-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                }
                .consultation-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                }
                .consultation {
                    display: flex;
                    flex-direction: column;
                    line-height: 1.1;
                }
                .phone-number {
                    font-size: 1.5rem;
                    font-weight: 900;
                    font-style: italic;
                }
                .iso {
                    position: absolute;
                    bottom: 25%;
                    right: 2.5rem;
                }
                .our-services-button {
                    font-weight: 700;
                    background: var(--primary-color); 
                    padding: 1rem 1.25rem;  
                }
                @media screen and (max-width: 480px) {
                    .Hero {
                        height: 34rem;
                        position: relative;
                        top: 0;
                        left: 0;
                    }
                    .video {
                        height: 34rem;
                    }
                    .h1-h2-wrapper {
                        height: fit-content;
                        width: unset;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        padding: 1rem;
                    }
                    .h1-h2-wrapper h1 {
                        max-width: 100%;
                        font-size: 1.5rem;
                        margin-top: 0; 
                    }
                    .h1-h2-wrapper h2 {
                        display: none;
                    }
                    .h1-h2-wrapper .h2-mobile {
                        display: block;
                        max-width: 100%;
                        font-size: 0.875rem;
                        margin-top: 0;
                        margin-bottom: 1rem; 
                    }
                    .other-wrapper {
                        flex-direction: column;
                        gap: 1rem;
                        align-items: flex-start;
                    }
                    .phone {
                        font-size: 1rem;
                    }
                    .consultation-wrapper {
                        font-size: 1rem;
                    }
                    .iso {
                        display: none;
                    }
                }
            `}</style>
        </div>
    )
}

export default Hero;