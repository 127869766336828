import React from 'react'

const ServicesIcons = () => {

  return (
    <div className='ServicesIcons'>

        <div className='services-icons'>
            <span className='services-icons-span'>Ние почистваме DPF/FAP филтри и катализатори</span>

            <div className='services-icons-wrapper'>
                <div>
                    <img src="images/icon-car.svg" alt="car" />
                    <span>Автомобили</span>
                </div>

                <div>
                    <img src="images/icon-microbus.svg" alt="microbus" />
                    <span>Микробуси</span>
                </div>

                <div>
                    <img src="images/icon-truck.svg" alt="truck" />
                    <span>Камиони</span>
                </div>

                <div>
                    <img src="images/icon-bus.svg" alt="bus" />
                    <span>Автобуси</span>
                </div>

                <div>
                    <img src="images/icon-tractor.svg" alt="tractor" />
                    <span>Селскостопанска техника</span>
                </div>
            </div>
        </div>

        <style jsx>{`
            .ServicesIcons {
                
            }
            .services-icons-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 4rem;
            }
            .services-icons {
                padding: 1.5rem 1rem 5rem 1rem;;
                text-align: center;
                background: var(--black-color);
                color: var(--white-color);
            }
            .services-icons-span {
                font-size: 1.5rem;
                font-weight: 800;
            }
            .services-icons-wrapper div {
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
            } 
            .services-icons-wrapper div img {
                margin-bottom: 1rem;
            }
            @media screen and (max-width: 480px) {
                .services-icons-wrapper {
                    flex-direction: column;
                    gap: 2rem;
                }
            }
        `}</style>
    </div>
  )
}

export default ServicesIcons