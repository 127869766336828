import React from 'react'

const About = () => {
	return (
		<div className='About'>
			<h1>За нас</h1>
			<p>DPF Cleaning е компания, посветена на професионалното почистване на DPF филтри и катализатори за леки и товарни автомобили. С дългогодишен опит и модерно оборудване, нашият екип осигурява бързо и ефективно обслужване, което подобрява работата на двигателя и намалява емисиите, като допринася за по-чиста и безопасна околна среда.</p>
			<p>Седалището ни е в град Поморие, а целта ни е да предложим на клиентите си надеждни и достъпни услуги с акцент върху качеството и вниманието към детайлите. Ние вярваме, че чистите филтри са ключът към оптимална работа на автомобила, по-малък разход на гориво и дългосрочна икономичност.</p>
			<p>Свържете се с нас и се убедете в качеството на нашите услуги!</p>
			
			<style jsx="true">{`
				.About {
					padding: var(--desktop-gap);
					background-color: var(--background-color);
					text-align: left;
				}
				h1 {
					text-align: center;
					background: var(--primary-color);
				}
			`}</style>
		</div>
	)
}

export default About