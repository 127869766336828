import Hero from "../components/Hero";
import Counters from "../components/Counters";
import Hero2 from "../components/Hero2";
import TrustAndQuality from "../components/TrustAndQuality";
import GreenLine from "../components/GreenLine";
import UsefulInformation from "../components/UsefulInformation";

const Home = () => (
	<div className="Home">
		<Hero />
		<Counters />
		<Hero2 />
		<TrustAndQuality/>
		<GreenLine/>
		<UsefulInformation/>

		<style jsx>{`
			.Home {

			}
		`}</style>
	</div>
);
  
export default Home;