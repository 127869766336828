import React from 'react'

const Services = () => {
	return (
		<section className="Services">
			<h1>Услуги</h1>



			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Почистване на DPF/FAP филтър и катализатори.
			</h2>
			<ul>
				<li>
					Машинното почистване на DPF/FAP филтри е прецизен и многослоен процес, който се състои от няколко ключови стъпки и може да отнеме до няколко часа. Основната му цел е пълното премахване на натрупаните сажди и пепел, като се възстановява ефективността на филтъра.
				</li>
				<li>
					Първата стъпка включва загряване на специален почистващ разтвор до температура от 85°C. След това, под високо налягане, течността се впръсква в обратна посока на нормалния поток на изгорелите газове. Това гарантира, че замърсяванията се отстраняват не само повърхностно, но и в дълбочина.
				</li>
				<li>
					Процедурата се допълва с контролирани промени в налягането и многократно обръщане на посоката на течността, което допринася за пълното премахване на замърсяванията. Така се осигурява цялостно почистване на филтъра и удължаване на неговия живот, без да се увреждат чувствителните компоненти.
				</li>
			</ul>

			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Физическо и софтуерно премахване на DPF/FAP филтър.
			</h2>
			<ul>
				<li>
					Физическото премахване на DPF/FAP филтъра е едно от най-надеждните решения за справяне с проблемите, свързани със запушени филтри. Ние в Dpf-cleaning  премахваме съдържанието на филтъра и възстановяваме фабричния му вид, като операцията е напълно незабележима.
				</li>
				<li>
				Но освен физическото премахване, е задължително и софтуерно премахване на DPF/FAP от управляващия блок на автомобила. Това предотвратява нежеланите регенерации и осигурява оптималната работа на двигателя, без риск от авариен режим или увеличен разход на гориво.
				</li>
			</ul>
			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Какво представлява DPF/FAP филтър ?
			</h2>

			<ul>
				<li>
					DPF филтър (Diesel Particulate Filter) е филтър за почистване на твърдите частици от изгорелите газове в изпускателната система на дизелови двигатели. Твърдите частици се образуват при изгаряне на дизеловото гориво в цилиндъра и горивната камера. С времето и пробега, тези частици се натрупват и запушват  така наречения DPF филтър. Това води до редица отрицателни последици, както за двигателя, така и за околната среда.
				</li>
				<li>
					Тъй като има лимитиран капацитет, за да се гарантира, че DPF филтъра ще продължава да работи добре, на определен период от време, трябва да се почиства или ако е твърде късно да се  подменя с нов.
				</li>
				<li>
					Намаляването на емисиите от дизеловите двигатели е едно от най-важните предизвикателства за качеството на въздуха.
				</li>
			</ul>

			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Ние почистваме DPF/FAP филтри  и катализатори на
			</h2>

			<ul className='vehicle-list'>
				<li>
					<img src="images/car-white.svg" alt="car-icon" />
					Автомобили
				</li>
				<li>
					<img src="images/bus-white.svg" alt="car-icon" />
					Микробуси
				</li>
				<li>
					<img src="images/truck-white.svg" alt="car-icon" />
					Камиони
				</li>
				<li>
					<img src="images/autobus-white.svg" alt="car-icon" />
					Автобуси
				</li>
				<li>
					<img src="images/tractor-white.svg" alt="car-icon" />
					Селскостопанска техника
				</li>
			</ul>

			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Грижа за вашия автомобил</h2>
			<ul>
				<li>
					С течението на времето, филтърът за твърди частици (DPF) се запушва и влошава нормалното функциониране на Вашия автомобил. Решението е машинно почистване по нова технология, без да се използват химикали като киселина и белина, които увреждат керамичното му покритие. Тъй нареченото пране с белина е изключително вредно за Вашия филтър. Киселината и белината увреждат керамичното покритие като го праят абразивно и лесно чупливо.
				</li>
				<li>
					Освен това белината избелва най-горния слой. Създава илюзия, че филтърът е почистен, но в дълбочина саждите стоят. Разполагаме с най-съвременната технология за пълно почистване на Вашите филтри, която чрез изпичане превръща твърдите частици (саждите, натрупани от изгорелите газове) в пепел.  След това чрез машина с  мощен вакуум се изсмуква пепелта.
				</li>
				<li>
					По този начин ние рециклираме Вашият филтър.
				</li>
			</ul>


			<h2>
				<img src="images/stroke.svg" alt="lines" />
				Какви са признаците, че DPF филтърът Ви е запушен?
			</h2>

			<ul>
				<li>
					Лампата за DPF свети на таблото
				</li>
				<li>
					Колата ви е авариен режим
				</li>
				<li>
					Чести опити колата да регенеирара DPF
				</li>
				<li>
					Охлаждащите вентилатори, работят по-често
				</li>
				<li>
					Нивото на маслото се увеличава
				</li>
				<li>
					Увеличена  консумация на гориво
				</li>
			</ul>

			<div className='danger'>
				Премахването на DPF филтъра е незаконно!
				Всеки притежател на дизелов двигател, към който не е прикрепен DPF, подлежи на глоби!
			</div>

			<style jsx="true">{`
				.Services {
					padding: var(--desktop-gap);
					background-color: var(--background-color);
					text-align: left;
				}
				h1 {
					text-align: center;
					background: var(--primary-color);
				}
				h2 {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
				.vehicle-list li {
					display: flex;
					align-items: center;
					gap: 1rem;
				}
				.danger {
					margin-top: 3rem;
					background: red;
					padding: 1rem;
					text-align: center;
					font-weight: bold; 
					border-radius: 1rem;
				}
			`}</style>
		</section>
	);
};

export default Services;