import React from "react"

const Hero2 = () => {

    return (
        <div className="Hero2">
            <div className="content">
                <h3 className="hero-2-h3">DPF ПОЧИСТВАНЕ Цени <span className="price">от 299 лв.</span></h3>
                <p>
                    Разполагаме с машини от последно поколение за ефективно и безопасно почистване на DPF и FAP филтри. Сертифицирани сме по ISO 9001, което гарантира качеството на нашите услуги и удовлетвореността на клиентите.
                </p>
                <ul className="hero-2-list">
                    <li> 
                        <img src="images/ticky.svg" alt="ticky" width={18} height={18}/>
                        Почистване на фиксирана цена без (ддс)
                    </li>

                    <li>
                        <img src="images/ticky.svg" alt="ticky" width={18} height={18}/>
                        Включва (Взимане и връщане)
                    </li>

                    <li>
                        <img src="images/ticky.svg" alt="ticky" width={18} height={18}/>
                        Включва тестов протокол
                    </li>

                    <li>
                        <img src="images/ticky.svg" alt="ticky" width={18} height={18}/>
                        12-Месечна Гаранция
                    </li>
                </ul>

                <div className='consultation-wrapper-green'>
                    <img src="images/calling.svg" alt="calling" width={36} height={36}/>
                    <div className='consultation'>
                        <span className='phone-number'>+359 887 776 177</span>
                        <span>свържете се за консултация</span>
                    </div>
                </div>
            </div>

            <div className="ad5000-wrapper">
                <img
                    src="images/ad5000s.png"
                    alt="ad5000"
                    className="ad5000"
                />
            </div>

            <style jsx>{`
                .Hero2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2rem; 
                    padding: 4rem 0 4rem 4rem;
                }
                .ad5000-wrapper {
                    display: flex;
                }
                .ad5000-wrapper {
                    object-fit: cover;
                }
                .content {
                    width: 50%;
                    align-self: flex-start;
                    text-align: left;                
                }
                .hero-2-h3 {
                    margin: 0;
                    font-size: 3rem;
                    font-weight: 900;
                    font-style: italic;
                    text-transform: uppercase;
                    line-height: 1.2;
                }
                .price {
                    color: var(--primary-color); 
                }
                .hero-2-list {
                    list-style: none;
                    padding: 0;
                }
                .hero-2-list li {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    gap: 0.5rem; 
                    margin-top: 0.25rem;
                }
                .consultation-wrapper-green {
                    display: flex;
                    align-items: center;
                    gap: 1rem; 
                    color: var(--white-color);
                    background: var(--primary-color);
                    width: fit-content;
                    padding: 1rem 1.125rem;
                }
                @media screen and (max-width: 480px) {
                    .Hero2 {
                        width: 100vw;
                        flex-direction: column;
                        padding: 0;
                    }
                    .content {
                        width: unset;
                        padding: 2rem;
                    } 
                    .hero-2-h3 {
                        font-size: 1.125rem;
                    }
                    .ad5000 {
                        width: 100vw;
                        height: 15rem;
                    }
                }
            `}</style>
        </div>
    )
};

export default Hero2;