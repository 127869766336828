import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Elastic} from 'react-burgers';
import {routes} from '../routes';

const Header = () => {
    const [isVissibleMenu, setIsVissibleMenu] = useState(false);

    const onClickBurger = () => {
        setIsVissibleMenu(prevState => prevState ? false : true);
    };

	const menuClass = classNames('menu', {
		'menu-closed': !isVissibleMenu
	});

    return (
        <div className='Header'>
           <Link to="tel:0887776177" className='phone-mobile'>
                <img src="/images/call-green.svg" alt="icon-phone" width={24} height={24} />
            </Link>

            <a href="/">
                <img src="/images/new-icon-logo.svg" alt="logo" className="logo"/>
            </a>

            <div className='links-container'>
                <Link to={routes.home} className="underline-effect">Начало</Link>
                <Link to={routes.services} className="underline-effect">Услуги</Link>
                <Link to={routes.about} className="underline-effect">За нас</Link>
                <Link to="tel:0887776177" className='phone'>
                    <img src="/images/call.svg" alt="icon-phone" width={24} height={24} />
                    <span>+359 887 776 177</span>
                </Link>
                <div className='burger' onClick={onClickBurger}>
                    <img src={`images/${!isVissibleMenu ? "new-burger-logo" : "close-icon"}.svg`} alt="burger" className='burger' />
                </div>
            </div>

            <ul className={menuClass}>
                <li onClick={onClickBurger}>
                    <Link to={routes.services} className='upper-row'>
                        <img src="/images/call-green.svg" alt="contact-icon" className='contacts-icon'/>
                        Услуги
                    </Link>
                </li>
                <li onClick={onClickBurger}>
                    <Link to={routes.about} className='upper-row'>
                        <img src="/images/location.svg" alt="location-icon" className='contacts-icon'/>
                        За нас
                   </Link>
                </li>
            </ul>
                
            <style jsx>{`
                .Header {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.5rem var(--desktop-gap) 0.5rem var(--desktop-gap);
                    height: 5.75rem;

                    .logo {
                        display: flex;
                        height: 4rem;
                    }

                    .links-container {
                        display: flex;
                        align-items: center;
                        gap: 2.5rem;
                    }

                    .links-container a {
                        font-size: 1.2rem;
                    }

                    .burger {
                        width: 2.5rem;
                        height: 2.5rem;
                        cursor: pointer;
                    }
                    .phone {
                        background: var(--primary-color);
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        padding: 1rem 1.25rem;
                    }
                    .phone-mobile {
                        display: none;
                    }
                    .menu {
                        position: absolute;
                        top: 100%;
                        right: 0;
                        width: 400px;
                        max-height: 12rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 1rem;
                        margin: 0 auto;
                        padding: 3rem 2rem;
                        background-color: #333333;
                        overflow: hidden;
                        z-index: 1;
                        transition: max-height 150ms linear, opacity 250ms ease-in-out;
                        opacity: 1;
                        pointer-events: all;
                    }

                    .menu-closed {
                        max-height: 0;
                        opacity: 0;
                        pointer-events: none;
                    }

                    li {
                        list-style: none;
                        text-shadow: 1px 1px 2px black;
                        display: flex;
                        flex-direction: column;
                        font-size: 1.5rem;
                    }

                    .upper-row {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    }

                    .contacts-span {
                        font-size: 1rem;
                    }

                    .contacts-icon {
                        width: 1.5rem;
                    }
                }

                @media screen and (max-width: 480px) {
                    .Header {
                        flex-direction: row-reverse;
                        text-wrap: nowrap;
                        padding: var(--mobile-gap);
                        height: 4rem;
                    }
                    .underline-effect {
                        display: none;
                    }
                    .Header .phone {
                        display: none; 
                    }
                    .phone-number {
                        font-size: 1rem;
                    }
                    .Header .logo {
                        display: flex;
                        height: 2rem;
                    }
                    .Header .phone-mobile {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0.25rem;
                        border-radius: 50%;
                    }
                    /* .links-container {
                        display: none;
                        gap: 0.5rem;
                    }

                    .burger {
                        display: block;
                        width: 2rem;
                        height: 2rem;
                    }

                    .menu {
                        width: calc(100vw - 4 * var(--mobile-gap));
                        text-wrap: wrap;
                        left: 0;
                        z-index: 10;
                    }

                    .underline-effect {
                        font-size: 1rem;
                    }

                    .logo {
                        display: none;
                    } */
                }
                @media screen and (min-width: 480px) {
                    .burger {
                        display: none;
                    }
                }
            `}</style>
        </div>
    )
};

export default Header;