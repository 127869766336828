// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* Color Variables */
    --primary-color: #3BAA35;
    --grey-color: #565656;
    --white-color: #FFFFFF;
    --black-color: #333333;
    
    /* Font Variables */
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: "Poppins", sans-serif;

    --font-weight-bold: 700;
    --font-weight-regular: 400;
  
    --heading-font-size: 2rem; 
    --body-font-size: 1rem;    

    /* Gaps */
    --desktop-gap: 4rem;
    --mobile-gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/variables.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,wBAAwB;IACxB,qBAAqB;IACrB,sBAAsB;IACtB,sBAAsB;;IAEtB,mBAAmB;IACnB,wCAAwC;IACxC,uCAAuC;;IAEvC,uBAAuB;IACvB,0BAA0B;;IAE1B,yBAAyB;IACzB,sBAAsB;;IAEtB,SAAS;IACT,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[":root {\n    /* Color Variables */\n    --primary-color: #3BAA35;\n    --grey-color: #565656;\n    --white-color: #FFFFFF;\n    --black-color: #333333;\n    \n    /* Font Variables */\n    --font-primary: 'Montserrat', sans-serif;\n    --font-secondary: \"Poppins\", sans-serif;\n\n    --font-weight-bold: 700;\n    --font-weight-regular: 400;\n  \n    --heading-font-size: 2rem; \n    --body-font-size: 1rem;    \n\n    /* Gaps */\n    --desktop-gap: 4rem;\n    --mobile-gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
