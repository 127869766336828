import {useState, useEffect} from 'react'

const Counters = () => {
    const [count, setCount] = useState(0);
    const [warrantyCount, setWarrantyCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount >= 200) {
                    clearInterval(interval);
                    return prevCount;
                }
                    return prevCount + 1; 
            });

        }, 10);

        const intervalTwo = setInterval(() => {
            setWarrantyCount(prevCount => {
                if (prevCount >= 5) {
                    clearInterval(interval);
                    return prevCount;
                }
                    return prevCount + 1;
            });
        }, 500);
    
        return () => {
            clearInterval(interval)
            clearInterval(intervalTwo)
        };
    }, []);

    return (
        <div className='Counters'>
            <li className='counter-element'>
                <img src="/images/icon-filter.svg" alt="dpf-icon" className='counter-icon'/>

                <div className='text-wrapper'>
                    <span className='counter'>
                        {count} 
                        <span className='plus'>+</span> 
                    </span>
                    <span>Почистени филтри</span>
                </div>
            </li>
            <li className='counter-element'>
                <img src="/images/icon-experience.svg" alt="waranty-icon" className='counter-icon'/>

                <div className='text-wrapper'>
                    <span className='counter'>
                        {warrantyCount} 
                        <span className='plus'>+</span> 
                    </span>
                    <span>Години опит</span>
                </div>
            </li>
            <li className='counter-element'>
                <img src="/images/icon-clients.svg" alt="profesionalism-icon" className='counter-icon'/>

                <div className='text-wrapper'>
                    <span className='counter'>
                        {count} 
                        <span className='plus'>+</span>
                    </span>
                    <span>Доволни клиенти</span>
                </div>
            </li>

            <style jsx>{`
                .Counters {
                    height: 9.275rem;
                    position: relative;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    color: var(--white-color);
                    background-color: var(--black-color);
                }
                .counter-element {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding: 1rem;
                }
                .counter-icon {
                    width: 5rem;
                    height: 5rem;
                }
                .text-wrapper {
                    display: flex;
                    flex-direction: column;
                }
                .counter {
                    font-size: 3rem;
                    font-weight: 900;
                    line-height: 1.2;
                }
                .plus {
                    color: var(--primary-color);
                }
                @media screen and (max-width: 480px) {
                    .Counters {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 2rem;
                        height: fit-content;
                        padding: 1rem;
                    }
                }
            `}</style>
        </div>
    )
}

export default Counters