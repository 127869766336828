import React from 'react'

const TrustAndQuality = () => {
  return (
    <div className='TrustAndQuality'>

        <div className='trust-and-quality-header'>
            <span>
                Нашите услуги
            </span>
            <h3>
                Надеждност и качество с всяка услуга
            </h3>
            <p>
                С грижа за вашия автомобил – предлагаме пълна гама услуги за почистване и поддръжка на DPF филтри. Доверете се на нашия опит и професионализъм за най-добрите резултати.
            </p>
        </div>

        <div className='trust-and-quality-content'>
            <div>
                <img src="images/icon-quality.svg" alt="quality" width={67} height={67}/>
                <h4>Гаранция за Качество</h4>
                <p>Постигаме високи стандарти чрез професионализъм и прецизност във всяка стъпка от процеса на почистване. Нашата цел е да осигурим дълготрайни резултати и пълна удовлетвореност на клиентите</p>
            </div>


            <div>
                <img src="images/icon-car.svg" alt="car" width={67} height={67}/>
                <h4>Всички Видове МПС</h4>
                <p>Защото почистваме DPF филтри на всички видове леки коли, автомобили, бусове – леко товарни, селскостопански машини, влекачи и тежкотоварни машини, дори ако е превозното средство е над 7.5 тона</p>
            </div>

            <div>
                <img src="images/icon-flame.svg" alt="flame" width={67} height={67}/>
                <h4>Система за Изпичане</h4>
                <p>Нашето оборудване разполага с уникална система за 'изпичане', която гарантира цялостно и ефективно почистване на DPF филтрите. Резултатът е по-добра работа на двигателя и дълготрайна защита</p>
            </div>
        </div>


        <style jsx>{`
            .TrustAndQuality {
                background-color: var(--black-color);
                color: var(--white-color);
                padding: 5rem;
                text-align: center; 
            }
            .trust-and-quality-header {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 0 auto 3rem auto;
                max-width: 60%; 
            }
            .trust-and-quality-header span { 
                font-style: italic; 
                font-weight: 900;
                color: var(--primary-color);
                font-size: 1.25rem;
            }
            .trust-and-quality-header h3 {
                font-size: 3rem; 
                font-style: italic; 
                font-weight: 900;
                line-height: 1.2;
                text-transform: uppercase;
                margin: 0;
            }
            .trust-and-quality-content {
                display: flex;
                align-items: center;
                justify-content: space-between; 
            }
            .trust-and-quality-content div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 33%;
            }
            .trust-and-quality-content h4 {
                font-size: 1.75rem;
                font-style: italic; 
                font-weight: 900;
                line-height: 1.2;
                margin: 0.75rem 0;
            }
            @media screen and (max-width: 480px) {
                .TrustAndQuality {
                    padding: 1rem;
                }
                .trust-and-quality-header h3 {
                    font-size: 1.5rem; 
                }
                .trust-and-quality-header {
                    margin: 0; 
                    max-width: unset;
                }
                .trust-and-quality-content div {
                    width: unset;
                }
                .trust-and-quality-content {
                    flex-direction: column;
                }
            }
        `}</style>
    </div>
  )
}

export default TrustAndQuality