import React from 'react'

const About = () => {

	return (
		<div className='About'>
			<section>
				<h1>За нас</h1>

				<p> 
					DPF Cleaning е компания, посветена на професионалното почистване на DPF филтри и катализатори за леки и товарни автомобили. С дългогодишен опит и модерно оборудване, нашият екип осигурява бързо и ефективно обслужване, което подобрява работата на двигателя и намалява емисиите, като допринася за по-чиста и безопасна околна среда. Седалището ни е в град Поморие, а целта ни е да предложим на клиентите си надеждни и достъпни услуги с акцент върху качеството и вниманието към детайлите. Ние вярваме, че чистите филтри са ключът към оптимална работа на автомобила, по-малък разход на гориво и дългосрочна икономичност.
				</p>
			</section>

			<section className='about-icons-section'>
				<div>
					<img src="images/icon-filter.svg" alt="filter" />
					<h2>
						Експертна грижа
					</h2>
					<span>
						Осигуряваме експертна грижа за вашия автомобил
					</span>
				</div>

				<div>
					<img src="images/icon-experience.svg" alt="experiance" />
					<h2>
						Гаранция за качество
					</h2>
					<span>
						Модерна технология и високи стандарти
					</span>
				</div>

				<div>
					<img src="images/icon-clients.svg" alt="clients" />
					<h2>
						Достъпни решения
					</h2>
					<span>
						Дълъг живот и по-ниски разходи за автомобила ви
					</span>
				</div>
			</section>

			<section className='about-hero-section'>

				<div className="content">
					<h3 className="hero-2-h3">
						Доверете се на <span className="price">експертите</span>
					</h3>
					<p>
						Нашите опитни механици работят със съвременна техника, за да осигурят прецизно почистване и диагностика на вашия DPF филтър. Всеки процес се извършва с внимание към детайла и гаранция за безопасност. От подготовката до завършването на услугата, ние осигуряваме качество, на което можете да разчитате.
					</p>
					<p>
						Посетете ни на адрес: гр. Поморие, Околовръстен път Бургас-Варна, до КПП.“
					</p>

					<div className='consultation-wrapper-green'>
						<img src="images/calling.svg" alt="calling" width={36} height={36}/>
						<div className='consultation'>
							<span className='phone-number'>+359 887 776 177</span>
							<span className="calling-consultation">свържете се за консултация</span>
						</div>
					</div>
            	</div>

				<div></div>
				<img src="images/about-image.png" alt="hero" />	
			</section>

			<style jsx>{`
				.About {
					padding: 2rem var(--desktop-gap);
				}
				.About section {
					margin-bottom: 10rem;
				}
				.About section h1 {
					font-size: 3rem;
					font-weight: 800;
					font-style: italic;
					text-transform: uppercase; 
				}
				.content {
					width: 50%;
					align-self: flex-start;
					text-align: left;                
				}
				.about-icons-section {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				.about-icons-section div {
					text-align: center;
				}
				.about-hero-section {
					display: flex;
				}
				.hero-2-h3 {
					margin: 0;
					font-size: 3rem;
					font-weight: 900;
					font-style: italic;
					text-transform: uppercase;
					line-height: 1.2;
				}
				.price {
					color: var(--primary-color); 
				}
				.hero-2-list {
					list-style: none;
					padding: 0;
				}
				.hero-2-list li {
					display: flex;
					align-items: center;
					font-weight: 600;
					gap: 0.5rem; 
					margin-top: 0.25rem;
				}
				.consultation-wrapper-green {
					display: flex;
					align-items: center;
					gap: 1rem; 
					color: var(--white-color);
					background: var(--primary-color);
					width: fit-content;
					padding: 1rem 1.125rem;
				}
				.consultation-wrapper-green .calling-consultation{
					padding-left: 1rem;
				}
                @media screen and (max-width: 480px) {
					.About {		
						padding: 2rem var(--mobile-gap);
					}
					.About .content {
						width: unset;
					}
					.about-icons-section {
						flex-direction: column;
					}
					.about-hero-section {
						flex-direction: column;
					}
					.consultation-wrapper-green {
						text-wrap: nowrap;
					}
					.consultation {
						display: flex;
						align-items: center;
						flex-direction: column;
					}
					.consultation-wrapper-green .calling-consultation {
						padding-left: 0;
					}
					.phone-number {
						font-size: 1.725rem;
						font-weight: 800;
						font-style: italic;
					}
				}
			`}</style>
		</div>
	);
};

export default About