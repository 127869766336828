// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* Color Variables */
    --primary-color: #3BAA35;
    --secondary-color: #3BAA35;
    --background-color: #333333;
    --text-color: #f5f5f5;
    --muted-text-color: #888888;
    
    /* Font Variables */
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: "Ubuntu", sans-serif;

    --font-weight-bold: 700;
    --font-weight-regular: 400;
  
    --heading-font-size: 2rem; 
    --body-font-size: 1rem;    
    
    --font-size-base: 16px;
    --font-size-large: 24px;
    --font-size-small: 12px;

    /* Spacing Variables */
    --spacing-small: 1rem;
    --spacing-medium: 2rem;
    --spacing-large: 3rem;
    
    /* Border Variables */
    --border-radius: 5px;
    --border-width: 1px;
    --border-color: #dddddd;
    
    /* Shadow Variables */
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Transition Variables */
    --transition-duration: 0.3s;
    --transition-easing: ease-in-out;
    
    /* Z-index Variables */
    --z-index-header: 100;
    --z-index-modal: 1000;
    --z-index-dropdown: 900;


    /* Gaps */

    --desktop-gap: 2rem;
    --mobile-gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/variables.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;IAC3B,qBAAqB;IACrB,2BAA2B;;IAE3B,mBAAmB;IACnB,wCAAwC;IACxC,sCAAsC;;IAEtC,uBAAuB;IACvB,0BAA0B;;IAE1B,yBAAyB;IACzB,sBAAsB;;IAEtB,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;;IAEvB,sBAAsB;IACtB,qBAAqB;IACrB,sBAAsB;IACtB,qBAAqB;;IAErB,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;;IAEvB,qBAAqB;IACrB,0CAA0C;;IAE1C,yBAAyB;IACzB,2BAA2B;IAC3B,gCAAgC;;IAEhC,sBAAsB;IACtB,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;;;IAGvB,SAAS;;IAET,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[":root {\n    /* Color Variables */\n    --primary-color: #3BAA35;\n    --secondary-color: #3BAA35;\n    --background-color: #333333;\n    --text-color: #f5f5f5;\n    --muted-text-color: #888888;\n    \n    /* Font Variables */\n    --font-primary: 'Montserrat', sans-serif;\n    --font-secondary: \"Ubuntu\", sans-serif;\n\n    --font-weight-bold: 700;\n    --font-weight-regular: 400;\n  \n    --heading-font-size: 2rem; \n    --body-font-size: 1rem;    \n    \n    --font-size-base: 16px;\n    --font-size-large: 24px;\n    --font-size-small: 12px;\n\n    /* Spacing Variables */\n    --spacing-small: 1rem;\n    --spacing-medium: 2rem;\n    --spacing-large: 3rem;\n    \n    /* Border Variables */\n    --border-radius: 5px;\n    --border-width: 1px;\n    --border-color: #dddddd;\n    \n    /* Shadow Variables */\n    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    \n    /* Transition Variables */\n    --transition-duration: 0.3s;\n    --transition-easing: ease-in-out;\n    \n    /* Z-index Variables */\n    --z-index-header: 100;\n    --z-index-modal: 1000;\n    --z-index-dropdown: 900;\n\n\n    /* Gaps */\n\n    --desktop-gap: 2rem;\n    --mobile-gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
