import React from 'react'
import ServicesIcons from '../components/ServicesIcons';

const Services = () => {
	return (
		<div className="Services">
			<section>
				<h1>Нашите услуги</h1>

				<p>
					Разполагаме с машини от последно поколение за ефективно и безопасно почистване на DPF и FAP филтри. Сертифицирани сме по ISO 9001, което гарантира качеството на нашите услуги и удовлетвореността на клиентите. Разполагаме с машини от последно поколение за ефективно и безопасно почистване на DPF и FAP филтри. Сертифицирани сме по ISO 9001, което гарантира качеството.
				</p>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Почистване на DPF/FAP филтър и катализатори
					</h2>
					<ul>
						<li>
							Машинното почистване на DPF/FAP филтри е прецизен и многослоен процес, който се състои от няколко ключови стъпки и може да отнеме до няколко часа. Основната му цел е пълното премахване на натрупаните сажди и пепел, като се възстановява ефективността на филтъра.
						</li>
						<li>
							Първата стъпка включва загряване на специален почистващ разтвор до температура от 85°C. След това, под високо налягане, течността се впръсква в обратна посока на нормалния поток на изгорелите газове. Това гарантира, че замърсяванията се отстраняват не само повърхностно, но и в дълбочина.
						</li>
						<li>
							Процедурата се допълва с контролирани промени в налягането и многократно обръщане на посоката на течността, което допринася за пълното премахване на замърсяванията. Така се осигурява цялостно почистване на филтъра и удължаване на неговия живот, без да се увреждат чувствителните компоненти.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Физическо и софтуерно премахване на DPF/FAP филтър
					</h2>
					<ul>
						<li>
							Физическото премахване на DPF/FAP филтъра е едно от най-надеждните решения за справяне с проблемите, свързани със запушени филтри. Ние в Dpf-cleaning премахваме съдържанието на филтъра и възстановяваме фабричния му вид, като операцията е напълно незабележима.
						</li>
						<li>
							Но освен физическото премахване, е задължително и софтуерно премахване на DPF/FAP от управляващия блок на автомобила. Това предотвратява нежеланите регенерации и осигурява оптималната работа на двигателя, без риск от аварийнен режим или увеличен разход на гориво.
						</li>
					</ul>
				</div>
			</section>
			<section>
				<div className='services-h2-ul-wrapper'>
					<h2>
						Какво представлява DPF/FAP филтър?
					</h2>

					<ul>
						<li>
							DPF филтър (Diesel Particulate Filter) е филтър за почистване на твърдите частици от изгорелите газове в изпускателната система на дизелови двигатели. Твърдите частици се образуват при изгаряне на дизеловото гориво в цилиндъра и горивната камера. С времето и пробега, тези частици се натрупват и запушват така наречения DPF филтър. Това води до редица отрицателни последици, както за двигателя, така и за околната среда.
						</li>
						<li>
							Тъй като има лимитиран капацитет, за да се гарантира, че DPF филтърът ще продължава да работи добре, на определен период от време, трябва да се почиства или ако е твърде късно да се подмени с нов.
						</li>
						<li>
							Намаляването на емисиите от дизеловите двигатели е едно от най-важните предизвикателства за качеството на въздуха.
						</li>
					</ul>
				</div>
			</section>

			<ServicesIcons />

			<section>
				<div className='services-h2-ul-wrapper'>
					<h2>
						Какво е FAP филтър ?
					</h2>
					<ul>
						<li>
							FAP (Filtre à Particules) филтърът е важен елемент в съвременната дизелова изпускателна система. От 2000 г. насам, при дизеловите автомобили с леко шаси, се използва филтър за частици, а с въвеждането на стандартите Евро-5 през 2011 г., употребата на филтър за твърди частици върху леки дизелови автомобили става задължителна.Филтърът за твърди частици е известен като DPF (Diesel Particulate Filter) на английски и FAP на френски (Filtre à Particules), и се използва основно в дизеловите автомобили на френски марки.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Какво прави FAP филтърът ?
					</h2>
					<ul>
						<li>
							FAP филтърът има за цел да намали емисиите на сажди и други вредни частици, които се отделят в атмосферата с изгорелите газове. Чрез филтриране на саждите, филтърът позволява намаляване на частици в отработените газове с до 99,9%. Когато саждите се натрупват във филтъра, те се изгарят чрез процес, наречен "регенерация". Това е необходима процедура, която се активира, когато температурата на изгорелите газове достигне над 550°C, като при това се изгарят саждите, а малкото пепел, което остава, се натрупва във филтъра.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Какво представлява катализаторът ?
					</h2>
					<ul>
						<li>
							Катализаторът е устройство, което се намира в изпускателната система на автомобилите и има за цел да намали нивото на токсични вещества в отработените газове. Той използва химически реакции, за да преобразува вредните молекули, които излизат от двигателя, в по-безвредни вещества.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Как работи катализаторът ?
					</h2>
					<ul>
						<li>
							Катализаторът съдържа специални материали (като платина, паладий и родий), които ускоряват химическите реакции, без да се изразходват в процеса. Когато изгорелите газове преминават през катализатора, те преминават през серия от химически реакции, които преобразуват вредните съединения в по-малко опасни вещества.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Какво е регенерация и защо е важно ?
					</h2>
					<ul>
						<li>
							Регенерацията на е процес, при който се премахват натрупаните сажди в филтъра, за да се възстанови неговата ефективност. Това се прави чрез повишаване на температурата на отработените газове, при което саждите изгарят и се превръщат в безвредни газове. Регенерацията е необходима, за да се предотврати запушването на филтъра и да се осигури нормалната работа на двигателя.
						</li>
					</ul>
				</div>

				<div className='services-h2-ul-wrapper'>
					<h2>
						Какви са признаците, че DPF филтърът Ви е запушен?
					</h2>

					<ul>
						<li>
							Лампата за DPF свети на таблото
						</li>
						<li>
							Колата ви е в авариен режим
						</li>
						<li>
							Чести опити колата да регенерира DPF
						</li>
						<li>
							Охлаждащите вентилатори работят по-често
						</li>
						<li>
							Нивото на маслото се увеличава
						</li>
						<li>
							Увеличена консумация на гориво
						</li>
					</ul>
				</div>
			</section>

			<style jsx>{`
				.Services {
					background-color: var(--white-color);
					text-align: left;
				}
				.Services section {
					padding: 2rem var(--desktop-gap);
				}
				.Services section h1 {
					font-size: 3rem;
					font-weight: 800;
					font-style: italic;
					text-transform: uppercase; 
				}
				.services-h2-ul-wrapper {
					border: 0.125rem solid var(--primary-color);
					padding: 2rem;
					margin-top: 2rem;
				}
				.Services ul {
					padding-left: 1rem;
				}
			`}</style>
		</div>
	);
};

export default Services;